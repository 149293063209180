<template>
  <div>
    <container v-if="homepage" :content="homepage"></container>
    <div v-else>Nothig to display</div>
  </div>
</template>
<script>
import container from '@/views/components/homepage/container.vue';
export default{
  components:{
    container
  },
  mounted(){
    window.onmessage=(ev)=>{
      console.log(ev.data)
      if(ev.data !=='get-homepage'){
        this.homepage= JSON.parse(ev.data)
      }
    }
  },
  data(){
    return {
      homepage:null
    }
  }
}
</script>